<template>
  <div
    class="px-2 pt-1"
  >
    <v-card
      flat
    >
      <v-list
        v-show="acciones.length > 0"
        avatar
        two-line
      >
        <template
          v-for="(accion, index) in acciones"
        >
          <v-list-item
            :key="index"
          >
            <v-list-item-avatar>
              <slot
                name="switchAccion"
                :accion="accion"
                :index="index"
              />
            </v-list-item-avatar>
            <v-list-item-content
              @click.stop="clickListItem(accion)"
            >
              <v-list-item-title>{{ accion.maccion.descripcion }} ({{ accion.maccion.descripcion_corta }})</v-list-item-title>
              <v-list-item-subtitle
                v-if="accion.accion_ejec.facturable"
              >
                Facturable
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-else
                class="red--text"
              >
                NO FACTURABLE
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="accion.accion_ejec.observaciones"
              >
                {{ accion.accion_ejec.observaciones }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-show="accion.accion_ejec.idest_accion_ejec === ACCION_EJEC.estados.pendiente"
            >
              <v-btn
                v-if="estadoPartePendiente"
                icon
                @click.stop="clickDelete(accion, index)"
              >
                <v-icon>
                  {{ $vuetify.icons.values.delete }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <v-card-text>
        <v-btn
          v-show="acciones.length === 0"
          block
          color="secondary"
          @click="clickAdd"
        >
          <v-icon
            left
            small
          >
            {{ $vuetify.icons.values.add }}
          </v-icon>
          Añadir acción
        </v-btn>
        <v-btn
          v-show="totalPreguntas > 0"
          block
          class="mt-2"
          color="error"
          @click="clickAddAnomalia"
        >
          <v-icon
            left
            small
          >
            {{ $vuetify.icons.values.warning }}
          </v-icon>
          Añadir anomalía
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ACCION_EJEC } from '@/utils/consts'

export default {
  props: {
    acciones: {
      type: Array,
      default: () => [],
    },
    totalPreguntas: {
      type: Number,
      default: 0,
    },
    estadoPartePendiente: {
      type: Boolean,
    },
  },
  data () {
    return {
      ACCION_EJEC,
    }
  },
  methods: {
    switchAccionChange (accion, index) {
      this.$emit('change', { accion, index })
    },
    clickDelete (accion, index) {
      this.$emit('delete', { accion, index })
    },
    clickAdd () {
      this.$emit('add-accion')
    },
    clickAddAnomalia () {
      this.$emit('add-anomalia')
    },
    clickListItem (item) {
      this.$emit('click-accion', item)
    },
  }
}
</script>